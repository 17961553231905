
/* eslint-disable no-unused-vars */

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';
import {
  CreateQueryParams,
  SConditionAND,
  SFields,
} from '@dataui/crud-request';
import { getModule } from 'vuex-module-decorators';

import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import PaymentAmount from './PaymentAmount.vue';
import CrudListPagination from '@gid/vue-common/components/crud-list/CrudListPagination.vue';
import CrudListTable from '@gid/vue-common/components/crud-list/CrudListTable.vue';
import CrudListErrorMessage from '@gid/vue-common/components/crud-list/CrudListErrorMessage.vue';
import {
  AccountingDocumentStatusEnum,
  AccountingDocumentTargetEnum,
  AccountingDocumentTypeEnum,
} from '@gid/models';

import {
  DocumentsBalanceActionsEnum,
  DocumentsBalanceModule,
} from '../../store/documents-balance.module';

interface TargetStatusSelection {
  key: string;
  value: AccountingDocumentStatusEnum;
}

@Component({
  components: {
    DatePicker,
    CrudListPagination,
    CrudListTable,
    CrudListErrorMessage,
    PaymentAmount,
  },
})
export default class AccountingDocumentsView extends Vue {
  inProgress: boolean = false;

  created() {
    this.find();
  }

  // Computed
  get store(): DocumentsBalanceModule {
    return getModule(DocumentsBalanceModule, this.$store);
  }
  get filterStatusOptions() {
    return Object.values(AccountingDocumentStatusEnum);
  }
  get filterDocumentTypes() {
    return Object.values(AccountingDocumentTypeEnum);
  }
  get filterTargetTypeOptions() {
    return Object.values(AccountingDocumentTargetEnum);
  }
  get columns() {
    return [
      {
        key: 'target.name',
        sortable: true,
        label: this.$t('accounting.common.target-name'),
      },
      {
        key: 'linkedPaymentsTotalMapped',
        sortable: true,
        label: this.$t('accounting.common.documents-total-amount-mapped'),
      },
      {
        key: 'paymentsTotal',
        sortable: true,
        label: this.$t('accounting.common.paid-total-amount'),
      },
      {
        key: 'mappedDocsMinusPaid',
        sortable: true,
        label: this.$t('accounting.common.mapped-docs-minus-paid'),
      },
      {
        key: 'documentsTotal',
        sortable: true,
        label: this.$t('accounting.common.documents-total-amount'),
      },
      {
        key: 'issuedDocsMinusPaid',
        sortable: true,
        label: this.$t('accounting.common.issued-docs-minus-paid'),
      },
      {
        key: 'paymentsIbanMatchTotal',
        sortable: true,
        label: this.$t('accounting.common.payments-iban-match-total'),
      },
    ];
  }

  @Watch('store.filters', {
    deep: true,
  })
  searchChange(value) {
    this.throttledFind();
  }

  buildSearch() {
    const search: Array<SFields | SConditionAND> = [];

    if (this.store.filters.dateFrom) {
      search.push({
        issueDate: {
          $gte: this.store.filters.dateFrom,
        },
      });
    }
    if (this.store.filters.dateTo) {
      search.push({
        issueDate: {
          $lte: this.store.filters.dateTo,
        },
      });
    }
    if (this.store.filters.targetType) {
      search.push({
        targetType: {
          $eq: this.store.filters.targetType,
        },
      });
    }

    if (this.store.filters.search) {
      search.push({
        $or: [
          {
            'jobs.name': {
              $contL: this.store.filters.search,
            },
          },
          {
            'target.name': {
              $contL: this.store.filters.search,
            },
          },
        ],
      });
    }

    return {
      search: {
        $and: search,
      },
    };
  }

  find() {
    const { search } = this.buildSearch();

    const query: CreateQueryParams = {
      page: this.store.pagination.currentPage,
      limit: this.store.pagination.perPage,
    };
    this.store[DocumentsBalanceActionsEnum.ITEMS_FETCH]({
      query,
      search,
    });
  }
  throttledFind = debounce(this.find, 400);
}
